import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { UserInfo } from '@/types';

export interface AppState {
	loaded: boolean;
	logged: boolean;
	user: UserInfo | null;
	counterDuration: number;
	timeLimit: number;
}

const state: AppState = {
	loaded: false,
	logged: false,
	user: null,
	counterDuration: 0,
	timeLimit: window.TOKEN_TTL // en minutes, à faire correspondre avec le TTL du token côté API
};

const actions: ActionTree<AppState, RootState> = {
	updateLoaded({ commit }, loaded) {
		commit('UPDATE_LOADED', loaded);
	},
	updateLogged({ commit }, logged) {
		commit('UPDATE_LOGGED', logged);
	},
	updateUser({ commit }, user) {
		commit('UPDATE_USER', user);
	},
	updateCounterDuration({ commit }, counterDuration) {
		commit('UPDATE_COUNTER_DURATION', counterDuration );
	}
};

const mutations: MutationTree<AppState> = {
	UPDATE_LOADED(state, loaded: boolean) {
		state.loaded = loaded;
	},
	UPDATE_LOGGED(state, logged: boolean) {
		state.logged = logged;
	},
	UPDATE_USER(state, user: UserInfo) {
		state.user = user;
	},
	UPDATE_COUNTER_DURATION(state, counterDuration: number) {
		state.counterDuration = counterDuration;
	}
  };

const getters: GetterTree<AppState, RootState> = {
	getColorTimer(state): string {
		return 'color: ' + (state.counterDuration < 5 ?  'red' : 'white');
	},

	getCounterDuration(state): string {
		const counterDuration = state.counterDuration;

		if (counterDuration === 0) {
			return '< 1 minute';
		}

		return `${counterDuration} minute${counterDuration > 1 ? 's' : ''}`;
	},

	getTimeLimit(state): number {
		return state.timeLimit;
	}
};

const appState: Module<AppState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};

export default appState;
